<template>
    <div class="box">
		<div class="row" v-if="!mapbox">
			<div class="col-12">
				<ErrorAlert :messageI18n="error_message" />
			</div>
		</div>
		<div v-if="mapbox">
			<e-address
				ref="eaddress"
				:lng.sync="lng"
				:lat.sync="lat"
			/>
			<l-map 
				id="map-modal"
				ref="map"
				:zoom="zoom" 
				:center="center"
				@click="addMarker"
				class="mt-3"
			>
				<l-tile-layer
					:url="url"
					:attribution="attribution"
				/>
				<l-marker v-if="position" :lat-lng="position">
				</l-marker>
			</l-map>
		</div>
    </div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Tiers from '@/mixins/Tiers.js'
    import { LMap, LTileLayer, LMarker, LControl, LPopup } from 'vue2-leaflet';
	import { Icon } from 'leaflet';
	import L from 'leaflet';
	import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
	import 'leaflet/dist/leaflet.css';
	import Places from 'vue-places'
	import Localisation from "@/mixins/Localisation.js"

	export default {
		name: "TiersLocalisation",
		props: ['tiers_id'],
		mixins: [Navigation, Localisation, Tools, Tiers],
		data () {
			return {
				position: null,
				zoom: 5,
				center: L.latLng(46.903, 1.909),
				url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
				attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
				processing: false,
                lng: null,
                lat: null,
				mapbox: null,
				error_message: this.getTrad('tiers.no_mapbox_key')
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.mapbox = this.getMapBoxKey()

                let current_tiers = await this.loadTier(this.tiers_id)

				this.init_marker()

                if(current_tiers.tiers_latitude && current_tiers.tiers_longitude) {
                    this.position = L.latLng(current_tiers.tiers_latitude, current_tiers.tiers_longitude)
                }
                else {
                    const search_param = current_tiers.tiers_address1 + ', ' + current_tiers.tiers_address2 + ', '
                        + current_tiers.tiers_postalcode + ', ' + current_tiers.tiers_town + ', ' + current_tiers.pays

                    this.$refs.eaddress.search(search_param)
                }

				delete Icon.Default.prototype._getIconUrl;
				Icon.Default.mergeOptions({
					iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
					iconUrl: require('leaflet/dist/images/marker-icon.png'),
					shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
				});
			},

			init_marker() {
				this.position = L.latLng(46.903, 1.909)
				this.center = this.position
				this.zoom = 15
			},

			addMarker(event) {
				this.position = event.latlng
			},

            set_marker(lat = null, lng = null) {
                if(lat) {
                    this.position = L.latLng(lat, this.lng)
                }
                if(lng) {
                    this.position = L.latLng(this.lat, lng)
                }
                this.center = this.position
            },

            async save_coordinates() {
                if(this.position.lat && this.position.lng) {
                    await this.save_tiers_coordinates(this.tiers_id, this.position.lat, this.position.lng)
                }
            }
        },
        watch: {
            lng(val) {
                this.set_marker(null, val)
            },
            lat(val) {
                this.set_marker(val, null)
            },
            async position(val) {
				if(this.$refs.map) {
					this.$refs.map.mapObject.setView(val, this.zoom)
					await this.save_coordinates()
				}
            }
        },
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            EAddress : () => import('GroomyRoot/components/Utils/EAddress'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            LMap,
			LTileLayer,
			LMarker,
			LControl,
			LPopup,
			'l-icon': Icon,
			'v-marker-cluster':Vue2LeafletMarkerCluster,
			Places
		}
	}
</script>

<style>
	@import "~leaflet.markercluster/dist/MarkerCluster.css";
	@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
	.leaflet-pane, .leaflet-control-container .leaflet-top{
		z-index: 1;
	}
	#map-modal{
		width: 100%;
		height: 700px;
	}
</style>